import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, Form } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { ClientsService } from '../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'header',
  templateUrl: 'header.component.html'
})
export class HeaderComponent implements OnInit{
	loading = true;
	location
	filteredLocation
	organization
	organizations
	allLocations
	role
	loggedRole
	roleToDisplay
	adminloctodisplay


	constructor(private clientService: ClientsService, private router: Router
	){
		this.role = localStorage.getItem('role');
		this.loggedRole = localStorage.getItem('loggedInRole');
		if(this.loggedRole == "super_administrator"){
			this.roleToDisplay = "Super Admin";
			this.adminloctodisplay = '';
		}
		if(this.loggedRole == "administrator"){
			this.roleToDisplay = "Partner Admin";
			this.adminloctodisplay = '('+localStorage.getItem('adminLocationname')+')';
		}
		if(this.loggedRole == "contributor"){
			this.roleToDisplay = "Contributor";
			this.adminloctodisplay = '('+localStorage.getItem('adminLocationname')+')';
		}
	}


	checkUser() {
		if(this.role.includes('sub-admin')) {
			return true;
		} else {
			return false;
		}
	}

	checkSuperAdmin() {
		if(this.role.includes('super_administrator')) {
			return true;
		} else {
			return false;
		}
	}

	checkContributor() {
		if(this.role.includes('contributor')) {
			return true;
		} else {
			return false;
		}
	}
	
	ngOnInit() {
		this.loading = false;
	}
	
	signOut(){
		this.router.navigateByUrl('/');
		this.clientService.sighOut().subscribe(res => {
			localStorage.clear();
		});
	}
}
