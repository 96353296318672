import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Http, Headers, RequestOptions, Response, URLSearchParams } from '@angular/http';
import {map} from 'rxjs/operators' 
import { environment } from '../../environments/environment';
import { BaseService } from './base.service'
import { Client, Location, Checklist, Tour } from './../interfaces/client';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn:"root"
})
export class ClientsService {
    // options:RequestOptions;
    public headerDropDown = new BehaviorSubject({client:'' ,location:''});
    public headerDropDownUpdte = new BehaviorSubject(false);
    public headerDropDownUpdteLocation = new BehaviorSubject(false);
    locationzoneData: any;
    constructor(private http: HttpClient, private baseService:BaseService) {}

    // organizations
    get_users() {
        return this.http.get(`${environment.config.API_URL}/api/v1/tenant_users`,this.baseService.getHttpClientHeader())
        .pipe(map(res => res))
    }

    get_organization(id) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get(`${environment.config.API_URL}/api/v1/organizations/${id}?tenant=${tenant}`,this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    }

    update_organization( organization: any ) {
        let tenant = localStorage.getItem('tenentDBName');
        organization["tenant"] = tenant;
        return this.http.put<Client>(`${environment.config.API_URL}/api/v1/organizations/${organization.id}`,organization,this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    }

    create_organization(data: any){
        let tenant = localStorage.getItem('tenentDBName');
        data["tenant"] = tenant;
        return this.http.post<Client>(`${environment.config.API_URL}/api/v1/organizations/`, data, this.baseService.getHttpClientHeader() )
        .pipe(map(res => res));
    }

    delete_organization(id: string) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.delete<Client>(`${environment.config.API_URL}/api/v1/organizations/${id}?tenant=${tenant}`,this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    }

    // locations
    get_locations() {
        return this.http.get<Location>(`${environment.config.API_URL}/api/v1/zones`, this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    }

    get_location(id) {
        return this.http.get(`${environment.config.API_URL}/api/v1/zones/${id}`)
        .pipe(map(res => res));;
    }

    get_admin_location() {
        return this.http.get(`${environment.config.API_URL}/api/v1/admin_location`, this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    }

    update_location( data: any ) {
     let locationId = data['id'];
     delete data['officers'];
     delete data['id'];
     delete data['location'];
     delete data['address']['id'];
     delete data['zone']['id']
         this.locationzoneData = {
            location : data,
            text_message:"",
            image:"",
            video:"",
            voice_recording:""
        };
        return this.http.put<Location>(`${environment.config.API_URL}/api/v1/zones/${locationId}`,this.locationzoneData, this.baseService.getHttpClientHeader())
        .pipe(map(res => res));;
    }

    create_location(data: any){
        delete data['location'];
        this.locationzoneData = {
            location : data,
            text_message:"",
            image:"",
            video:"",
            voice_recording:""
        };
        return this.http.post<Location>(`${environment.config.API_URL}/api/v1/zones/`, this.locationzoneData, this.baseService.getHttpClientHeader() )
        .pipe(map(res => res));;
    }

    delete_location(id: string) {
       // let tenant = localStorage.getItem('tenentDBName');
        return this.http.delete<Location>(`${environment.config.API_URL}/api/v1/zones/${id}`, this.baseService.getHttpClientHeader())
        .pipe(map(res => res));;
    }

    get_geocode(data: any) {
        let tenant = localStorage.getItem('tenentDBName');
        data["tenant"] = tenant;
        return this.http.post<any>(`${environment.config.API_URL}/api/v1/services/geocode`,data, this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    }

    // checklist

    get_checkpoints(){
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Checklist>(`${environment.config.API_URL}/api/v1/checkpoints?tenant=${tenant}`,this.baseService.getHttpClientHeader())
        .pipe(map(res => res))
    }
    get_checkpoint(id) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get(`${environment.config.API_URL}/api/v1/checkpoints/${id}?tenant=${tenant}`);
    }
    update_checkpoint( location: any ) {
        let tenant = localStorage.getItem('tenentDBName');
        location["tenant"] = tenant;
        return this.http.put<Checklist>(`${environment.config.API_URL}/api/v1/checkpoints/${location.id}`,location, this.baseService.getHttpClientHeader());
    }

    create_checkpoint(data: any){
        let tenant = localStorage.getItem('tenentDBName');
        data["tenant_name"] = tenant;
        return this.http.post<Checklist>(`${environment.config.API_URL}/api/v1/checkpoints/`, data, this.baseService.getHttpClientHeader() )
        .pipe(map(res => res));
    }

    delete_checkpoint(id: string) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.delete(`${environment.config.API_URL}/api/v1/checkpoints/${id}?tenant=${tenant}`, this.baseService.getHttpClientHeader());
    }
    generate_code(){
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Checklist>(`${environment.config.API_URL}/api/v1/checkpoints/generate_code?tenant=${tenant}`,this.baseService.getHttpClientHeader())
        .pipe(map(res => res))
    }
    print_barcodes(ids){
        let tenant = localStorage.getItem('tenentDBName');
        window.open("/api/v1/checkpoints/print_barcodes?ids=" +  ids.join(","), "_blank");
        // return this.http.get<Checklist>(`${environment.config.API_URL}/api/v1/officers/`,this.baseService.getHttpClientHeader())
        // .pipe(map(res => res))
    }


    // checklist

    get_tours(){
        return this.http.get<Tour>(`${environment.config.API_URL}/api/v1/tours`,this.baseService.getHttpClientHeader())
        .pipe(map(res => res))
    }
    get_tour(id) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Tour>(`${environment.config.API_URL}/api/v1/tours/${id}?tenant=${tenant}`);
    }
    update_tour( tour: any ) {
        return this.http.put<Tour>(`${environment.config.API_URL}/api/v1/tours/${tour.id}`,tour, this.baseService.getHttpClientHeader());
    }

    create_tour(data: any){
        return this.http.post<Tour>(`${environment.config.API_URL}/api/v1/tours/`, data, this.baseService.getHttpClientHeader() )
        .pipe(map(res => res));
    }

    create_message(data: any){
        return this.http.post(`${environment.config.API_URL}/api/v1/admin_messages/`, data, this.baseService.getHttpClientHeader() )
        .pipe(map(res => res));
    }
    update_message(message: any, id: string){
        return this.http.put(`${environment.config.API_URL}/api/v1/admin_messages/${id}`, message, this.baseService.getHttpClientHeader());
    }

    get_messages(){
        return this.http.get(`${environment.config.API_URL}/api/v1/admin_messages`,this.baseService.getHttpClientHeader())
        .pipe(map(res => res))
    }

    delete_message(id: string) {
        return this.http.delete(`${environment.config.API_URL}/api/v1/admin_messages/${id}`, this.baseService.getHttpClientHeader());
    }

    delete_tour(id: string) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.delete<Tour>(`${environment.config.API_URL}/api/v1/tours/${id}?tenant=${tenant}`, this.baseService.getHttpClientHeader());
        }

    // officer



    // officer
    get_officers() {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Client>(`${environment.config.API_URL}/api/v1/officers?tenant=${tenant}`, this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    }

    get_officer(id) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Client>(`${environment.config.API_URL}/api/v1/officers/${id}?tenant=${tenant}`);
    }

    update_officer( officer: any ) {
        let tenant = localStorage.getItem('tenentDBName');
        officer["tenant"] = tenant;
        return this.http.put<Client>(`${environment.config.API_URL}/api/v1/officers/${officer.id}`,officer, this.baseService.getHttpClientHeader());
    }
    
    get_user(id) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get(`${environment.config.API_URL}/api/v1/users/${id}`, this.baseService.getHttpClientHeader());
    }
    update_user(userId, contributorRole) {
        let user = {
        "is_contributor": contributorRole
        };

        return this.http.put(`${environment.config.API_URL}/api/v1/tenant_users/${userId}`,user, this.baseService.getHttpClientHeader());
    }

    create_user(data: any){
        let tenant = localStorage.getItem('tenentDBName');
        data["tenant"] = tenant;
        return this.http.post(`${environment.config.API_URL}/api/v1/users/`, data, this.baseService.getHttpClientHeader() )
        .pipe(map(res => res));
    }

    delete_users(id: string) {
        return this.http.delete(`${environment.config.API_URL}/api/v1/tenant_users/${id}`, this.baseService.getHttpClientHeader());
    }
    


    // settings
    get_settings() {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Client>(`${environment.config.API_URL}/api/v1/settings?tenant=${tenant}`, this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    }
    
    update_setting( setting: any ) {
        let tenant = localStorage.getItem('tenentDBName');
        setting["tenant"] = tenant;
        return this.http.put(`${environment.config.API_URL}/api/v1/settings/`,setting, this.baseService.getHttpClientHeader());
    }

    create_setting(data: any){
        let tenant = localStorage.getItem('tenentDBName');
        data["tenant"] = tenant;
        return this.http.post(`${environment.config.API_URL}/api/v1/settings/`, data, this.baseService.getHttpClientHeader() )
        .pipe(map(res => res));
    }

    delete_setting(id: string) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.delete(`${environment.config.API_URL}/api/v1/settings/${id}?tenant=${tenant}`, this.baseService.getHttpClientHeader());
        }
    
    sighOut(){
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get(`${environment.config.API_URL}/signout?tenant=${tenant}`, this.baseService.getHttpClientHeader() )
        .pipe(map(res => res));
    }

    get_shift_logs() {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Client>(`${environment.config.API_URL}/api/v1/shift_logs?tenant=${tenant}`, this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    };

    get_shift_logs_for_date(date, name, clientId, locationId) {
        let tenant = localStorage.getItem('tenentDBName');
        let querydata = {
            selectedDate : date,
            officer : name,
            client: clientId,
            location: locationId
        }
        let queryParams = `?status=True`;

        // queryParams = '?' + querydata.selectedDate ? querydata.selectedDate : ''  + '&' + querydata.officer ? querydata.officer : ''  + '&' + querydata.client ? querydata.client : '' + '&' + querydata.location ? querydata.location  : '' 

        if (!name && date && clientId && locationId) {
            queryParams = '?date_filter=' + date + '&client_filter=' + clientId + '&loc_filter=' + locationId;
        } else if (name && !date && !clientId && !locationId) {
            queryParams = '?name_filter=' + name ;
        } else if (name && date && !clientId && !locationId) {
            queryParams = '?name_filter=' + name + '&date_filter=' + date;
        } else if (name && date && clientId && !locationId) {
            queryParams = '?name_filter=' + name + '&date_filter=' + date + '&client_filter=' + clientId;
        } else if (name && date && clientId && locationId) {
            queryParams = '?name_filter=' + name + '&date_filter=' + date + '&client_filter=' + clientId + '&loc_filter=' + locationId;
        } else if (!name && !date && !clientId && locationId) {
            queryParams = '?loc_filter=' + locationId;
        } else if (!name && !date && clientId && !locationId) {
            queryParams = '?client_filter=' + clientId;
        } else if (!name && date && !clientId && !locationId) {
            queryParams = '?date_filter=' + date;
        } else if (name && date) {
            queryParams = '?name_filter=' + name + '&date_filter=' + date;
        } else if (name && clientId) {
            queryParams = '?name_filter=' + name + '&client_filter=' + clientId;
        } else if (name && locationId) {
            queryParams = '?name_filter=' + name + '&loc_filter=' + locationId;
        } else if (date && clientId) {
            queryParams = '?date_filter=' + date + '&client_filter=' + clientId;
        } else if (date && locationId) {
            queryParams = '?date_filter=' + date + '&loc_filter=' + locationId;
        } else if (clientId && locationId) {
            queryParams = '?client_filter=' + clientId+ '&loc_filter=' + locationId;
        } else if (clientId ) {
            queryParams = '?client_filter=' +clientId ;
        } else if (date) {
            queryParams = '?date_filter=' + date;
        } else if (locationId) {
            queryParams = '?loc_filter=' + locationId;
        }
        return this.http.get<Client>(`${environment.config.API_URL}/api/v3/shift_logs/` + queryParams + `&tenant=${tenant}`,this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    };

    get_shift_logs_for_user(id) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Client>(`${environment.config.API_URL}/reports?tenant=${tenant}&view_officer_id=` + id,this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    };

    get_shift_log(id) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Client>(`${environment.config.API_URL}/api/v2/shift_logs/${id}?tenant=${tenant}`, this.baseService.getHttpClientHeader())
        // return this.http.get<Client>(`${environment.config.API_URL}/api/v3/shift_logs/${id}/log_entries?tenant=${tenant}`, this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    };

    // export_shift_logs(ids) {
    //     if (ids.length == 0) {
    //         return;
    //     }

    //     // http://stackoverflow.com/a/13929963/1055722
    //     var f = document.createElement("iframe");
    //     f.style.position = "absolute";
    //     f.style.left = "-10000px";
    //     f.src = window.location.protocol + "//" + window.location.host + "/api/v1/shift_logs/export?ids=" + ids.join(",");
    //     document.body.appendChild(f);
    // };

    delete_shift_logs(ids) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.delete(`${environment.config.API_URL}/api/v1/shift_logs/?tenant=${tenant}&ids=` + ids.join(","), this.baseService.getHttpClientHeader())
        .pipe(map(res => res));
    };
    
    // subscription
    get_subscriptions() {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get<Client>(`${environment.config.API_URL}/api/v1/plans?tenant=${tenant}`, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }

    get_subscription(id) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get(`${environment.config.API_URL}/api/v1/plans/${id}?tenant=${tenant}`);
    }

    update_subscription(plan: any) {
        let tenant = localStorage.getItem('tenentDBName');
        plan["tenant"] = tenant;
        return this.http.put<Client>(`${environment.config.API_URL}/api/v1/plans/${plan.id}`, plan, this.baseService.getHttpClientHeader());
    }

    create_subscription(data: any) {
        let tenant = localStorage.getItem('tenentDBName');
        data["tenant"] = tenant;
        return this.http.post(`${environment.config.API_URL}/api/v1/plans/`, data, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }

    delete_subscription(id: string) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.delete(`${environment.config.API_URL}/api/v1/plans/${id}?tenant=${tenant}`, this.baseService.getHttpClientHeader());
    }

    // tenant-accounts
    get_accounts() {
        return this.http.get<Client>(`${environment.config.API_URL}/api/v1/multitenancy`, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }

    get_account(id) {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get(`${environment.config.API_URL}/api/v2/multitenancy/${id}`);
    }

    update_account(user: any) {
        let tenant = localStorage.getItem('tenentDBName');
        user["tenant"] = tenant;
        return this.http.put(`${environment.config.API_URL}/api/v2/multitenancy/${user.id}`, user, this.baseService.getHttpClientHeader());
    }

    create_account(data: any) {
        let tenant = localStorage.getItem('tenentDBName');
       // data["tenant"] = tenant;
        return this.http.post(`${environment.config.API_URL}/api/v1/multitenancy/`, data, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }

    subscriptions(data: any) {
        let tenant = localStorage.getItem('tenentDBName');
        data["tenant"] = tenant;
        return this.http.post(`${environment.config.API_URL}/api/v1/subscriptions/`, data, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }

    getSubscriptions() {
        let tenant = localStorage.getItem('tenentDBName');
        return this.http.get(`${environment.config.API_URL}/api/v1/subscriptions?tenant=${tenant}`, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }

    // get_subscriptionData(id) {
    //     let tenant = localStorage.getItem('tenentDBName');
    //     return this.http.get(`${environment.config.API_URL}/api/v2/multitenancy/${id}?tenant=${tenant}`);
    // }

    delete_account(id: string) {
        return this.http.delete(`${environment.config.API_URL}/api/v1/multitenancy/${id}`, this.baseService.getHttpClientHeader());
    }

    delete_image(id: string){
         return this.http.delete(`${environment.config.API_URL}/api/v1/upload_image/?id=${id}`, this.baseService.getHttpClientHeader());
    }

    delete_audio(id: string){
         return this.http.delete(`${environment.config.API_URL}/api/v1/upload_audio/?id=${id}`, this.baseService.getHttpClientHeader());
    }

    delete_video(id: string){
         return this.http.delete(`${environment.config.API_URL}/api/v1/upload_video/${id}`, this.baseService.getHttpClientHeader());
    }

    delete_zone_comment(id: string){
         return this.http.delete(`${environment.config.API_URL}/api/v1/comments/?id=${id}`, this.baseService.getHttpClientHeader());
    }

    vetting(data: any){
       return this.http.post(`${environment.config.API_URL}/api/v1/vetting/`, data, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }

    vetMediaInfo(data: any){
       return this.http.post(`${environment.config.API_URL}/api/v1/vet_media/`, data, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }

    add_comment(data: any){
       return this.http.post(`${environment.config.API_URL}/api/v1/comments/`, data, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }

    upload_image(formData: any) {
    return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
               if (xhr.status == 200) {
                  resolve((JSON.parse(xhr.response)));
               } else {
                reject(xhr.response);
               }
            }
            }
            var url = `${environment.config.API_URL}/api/v1/upload_image/`;
            xhr.open("POST", url, true);
            xhr.setRequestHeader('Access-Control-Allow-Credentials','true');
            xhr.setRequestHeader('Access-Control-Allow-Methods','*');
            xhr.setRequestHeader('Access-Control-Allow-Origin','*');
            xhr.setRequestHeader('tenant',localStorage.getItem('tenentDBName'));
            xhr.withCredentials = true;
            xhr.send(formData);
        });  
    }
    upload_audio(formData: any) {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
               if (xhr.status == 200) {
                  resolve((JSON.parse(xhr.response)));
               } else {
                reject(xhr.response);
               }
            }
            }
            var url = `${environment.config.API_URL}/api/v1/upload_audio/`;
            xhr.open("POST", url, true);
            xhr.setRequestHeader('Access-Control-Allow-Credentials','true');
            xhr.setRequestHeader('Access-Control-Allow-Methods','*');
            xhr.setRequestHeader('Access-Control-Allow-Origin','*');
            xhr.setRequestHeader('tenant',localStorage.getItem('tenentDBName'));
            xhr.withCredentials = true;
            xhr.send(formData);
        });
    }

    upload_video(formData: any) {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
               if (xhr.status == 200) {
                  resolve((JSON.parse(xhr.response)));
               } else {
                reject(xhr.response);
               }
            }
            }
            var url = `${environment.config.API_URL}/api/v1/upload_video/`;
            xhr.open("POST", url, true);
            xhr.setRequestHeader('Access-Control-Allow-Credentials','true');
            xhr.setRequestHeader('Access-Control-Allow-Methods','*');
            xhr.setRequestHeader('Access-Control-Allow-Origin','*');
            xhr.setRequestHeader('tenant',localStorage.getItem('tenentDBName'));
            xhr.withCredentials = true;
            xhr.send(formData);
        });
    }
    get_tenantslist(){
        return this.http.get(`${environment.config.API_URL}/api/v1/tenants`, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }
    post_tenantslist(data:any){
        return this.http.post(`${environment.config.API_URL}/api/v1/tenants/`, data, this.baseService.getHttpClientHeader())
            .pipe(map(res => res));
    }
}