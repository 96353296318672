import { Component, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'message',
  templateUrl: 'message.component.html'
})
export class MessageComponent{ 

  constructor(private dialog: MatDialog, private ref: MatDialogRef<any>) {
  }
  
  delete(){
    this.ref.close('yes');
  }

  cancle(){
    this.ref.close('no');
  }

}
