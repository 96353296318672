//Admin access for all routes
export const ADMIN_ACCESS = {
	"admin": true,
	"admin/plans": true,
	"admin/plans/new": true,
	"admin/plans/:id/edit": true,
	"admin/users": true,
	"admin/users/new": true,
	"admin/users/:id": true,
	"admin/users/:id/edit": true,
	"admin/companies": true,
	"admin/companies/new": true,
	"admin/companies/:id/edit": true,
	"admin/profile/:id": true,
	"dashboard": false,
	"clients": false,
	"clients/new": false,
	"clients/:id/edit": false,
	"employees": false,
	"employees/new": false,
	"employees/:id/edit": false,
	"plans": false,
	"plans/:id/card-details": false,
	"profile/:id": false,
	"templates": true,
}

//Comapny access routes
export const COMPANY_ACCESS = {
	"dashboard": true,
	"clients": true,
	"clients/new": true,
	"clients/:id/edit": true,
	"employees": true,
	"employees/new": true,
	"employees/:id/edit": true,
	"plans": true,
	"plans/:id/card-details": true,
	"templates": true,
	"admin": false,
	"admin/plans": false,
	"admin/plans/new": false,
	"admin/plans/:id/edit": false,
	"admin/users": false,
	"admin/users/new": false,
	"admin/users/:id": false,
	"admin/users/:id/edit": false,
	"admin/companies": false,
	"admin/companies/new": false,
	"admin/companies/:id/edit": false,
	"admin/profile/:id": false,
}
