﻿import { Injectable } from '@angular/core';
import { Route, Router, CanLoad, CanActivate, NavigationExtras, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
	currentUser: any = {};

	constructor(
		private router: Router
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const checkToken = this.checkLogin();
		if (checkToken) {
			return true;
		} else {
			return false;
		}
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this.canActivate(route, state);
	}

	checkLogin() {
		const sessionId = localStorage.getItem('XSRF-TOKEN');
		if (sessionId == null || sessionId === '' || !sessionId) {
			this.router.navigate(['/login']);
			return false;
		} else {
			if (localStorage.getItem('client_filter')) {
				localStorage.removeItem('client_filter');
			}
			return true;
		}
	}
}
