interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/admin/dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Users',
    url: '/admin/users',
    icon: 'fa fa-users',
  },
  {
    name: 'Challenges',
    url: '/admin/challenges',
    icon: 'fa fa-gift'
  },
  {
    name: 'Devices',
    url: '/admin/devices',
    icon: 'fa fa-mobile',
    // children: [{
    //   name: 'Add',
    //   url: '/admin/devices',
    //   icon: 'icon-puzzle'
    // }],
  },
  {
    name: 'Tokens',
    url: '/admin/tokens',
    icon: 'fa fa-connectdevelop'
  },
  {
    name: 'Pages',
    url: '/admin/devices',
    icon: 'fa fa-mobile',
    children: [{
      name: 'Guide Management',
      url: '/admin/guides',
      icon: 'icon-puzzle'
    },{
      name: 'Video Management',
      url: '/admin/videos',
      icon: 'fa fa-video-camera'
    },{
      name: 'Static Pages',
      url: '/admin/devices',
      icon: 'fa fa-book'
    },
  ]
  },
];
