﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response, URLSearchParams } from '@angular/http';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn:"root"
})
export class UserService {
    options:RequestOptions;

    constructor(private http: HttpClient) { }

    getCurrent() {
        return this.http.get(`${environment.config.API_URL}/users/current`);
    }

    register(user) {
        return this.http.post(`${environment.config.API_URL}/users`, user);
    }

    forgotPassword( email: any ) {        
        return this.http.post(`${environment.config.API_URL}/users/forgotPassword`, {email: email});
    }

    resetPassword(data: any){        
        return this.http.post(`${environment.config.API_URL}/users/resetPassword`, data );
    }

    checkResetLink(url: string) {
        return this.http.post(`${environment.config.API_URL}/users/checkResetLink`, {url: url});  
    }

    checkPassword(password: string, _id){
        const obj = {_id: _id, password: password};
        return this.http.post(`${environment.config.API_URL}/users/checkPassword`, obj);
    }

    confirmRegisterStatus(url: string) {
        return this.http.post(`${environment.config.API_URL}/users/confirmRegisterStatus`, {url: url});
    }

}