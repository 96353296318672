import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ADMIN_ACCESS } from '../shared/admin.access';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public isLoggedIn = new BehaviorSubject(true);
  public loader = new BehaviorSubject(false);
  public ondashboard = new BehaviorSubject(true);
  public updateData = new BehaviorSubject({status:false,page:''});
  public pinDroppedOnceOnClick = new BehaviorSubject(false);
  public role = new BehaviorSubject("");
  public currentUser = new BehaviorSubject<any>(null);

  private default_permissions = JSON.parse(JSON.stringify(ADMIN_ACCESS));
  private permissions = new BehaviorSubject<any>(  this.default_permissions );

  private locations = new BehaviorSubject("not changed")
  locations_list = this.locations.asObservable()
  /*
  * Need this through out the app to check user type
  */
  // currentPermissions = this.permissions.asObservable();

  update_location_list(message: string) {
    this.locations.next(message);
  }

  constructor() { }

  setPermissions(data: any) {
    // this.permissions.next(data)
  }

}
