﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { DataService } from './data.service';
import { locale } from 'moment';
import { Observable } from 'rxjs';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Injectable({
    providedIn:"root"
})
export class AuthenticationService implements HttpInterceptor{
    constructor(private http: HttpClient,
        private dataService: DataService,
        private router: Router,
        private htp: Http
    ) { }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request);
    }
    
    login(email: string, password: string, dbname: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8').append('Access-Control-Allow-Origin', '*').append('tenant', dbname);
        const httpOptions = {
            headers: headers,
            withCredentials: true
           };
        return this.http.post<{code:number,message:string,"XSRF-TOKEN":string,data:any }>(
            `${environment.config.API_URL}/api/v1/services/authenticate`,
            { email_address: email, password: password,"cancel_other_session":true },httpOptions)
        .pipe(map(user => {
            return user;
        }));
    }

    forgotPassword(email: string) {
        return this.http.post<any>(`${environment.config.API_URL}/forgot_password`, { email_address: email})
        .pipe(map(user => {
            return user;
        }));
    }

    clearData() {
        this.dataService.isLoggedIn.next(false);
        this.dataService.role.next(null);
        this.dataService.currentUser.next(null);
        this.dataService.setPermissions("");
        // remove user from local storage to log user out
        localStorage.clear();
    }

    logout() {
        this.dataService.isLoggedIn.next(false);
        this.dataService.role.next(null);
        this.dataService.currentUser.next(null);
        this.dataService.setPermissions("");
        // remove user from local storage to log user out
        localStorage.clear();
        this.router.navigate(['/']);
    }
}