import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { SidebarComponent } from './containers/sidebar/sidebar.component';
import { HeaderComponent } from './containers/header/header.component';
import { FooterComponent } from './containers/footer/footer.component';
import { MessageComponent } from './containers/message/message.component';

// Import Views
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';


export const APP_CONTAINERS = [
	DefaultLayoutComponent,
	SidebarComponent,
	HeaderComponent,
	FooterComponent,
	MessageComponent,
	P404Component,
	P500Component,
];

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: '404',
		component: P404Component,
		data: {
			title: 'Page 404'
		}
	},
	{
		path: '500',
		component: P500Component,
		data: {
			title: 'Page 500'
		}
	},
	{
		path: 'login',
		loadChildren: './views/login/login.module#LoginModule'
	},
	{
		path: 'forgot-password',
		loadChildren: './views/forgot-password/forgot-password.module#ForgotPasswordModule'
	},
	{
		path: 'reset-password',
		loadChildren: './views/reset-password/reset-password.module#ResetPasswordModule'
	},
	{
		path: 'admindashboard',
		component: DefaultLayoutComponent,
		loadChildren: './views/admindashboard/admindashboard.module#AdmindashboardModule'
	},
	{
		path: 'contributordashboard',
		component: DefaultLayoutComponent,
		loadChildren: './views/contributordashboard/contributordashboard.module#ContributordashboardModule'
	},
	{
		path: 'tours',
		component: DefaultLayoutComponent,
		loadChildren: './views/tours/tours.module#ToursModule'
	},
	{
		path: 'dashboard',
		component: DefaultLayoutComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Home'
		},
		children: [
			{
				path: '',
				loadChildren: './views/dashboard/dashboard.module#DashboardModule'
			}
		]
	},
	{ path: '**', component: P404Component }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
