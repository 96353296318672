import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import {Observable} from "rxjs";
import { environment } from '../../environments/environment';
import {Router} from '@angular/router';
// import { NotificationsService } from '../shared/notifications.service';

@Injectable({
  providedIn:"root"
})

export class BaseService {
  url = new Map;
  // notifier: NotificationsService = null;
  constructor(private http: Http,private router: Router, httpClient: HttpClient) {
    this.http = http;
    // this.notifier = notifier;

    // this.url.set('socket', environment.config.SOCKET_URL);
    // this.url.set('base', environment.config.BASE_URL);
    this.url.set('api', environment.config.API_URL);
    // this.url.set('suffix', suffix);
  }

  authToken() {
    const headers = new Headers({ 
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('currentUser')).token}`
    });
    return new RequestOptions({ headers: headers});
  }

  getHeader() {
    const headers = new Headers({ 
      // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('currentUser')).token}`
      'Content-Type': 'application/json'
    });
    return new RequestOptions({ headers: headers, withCredentials: true });
  }

  getHttpClientHeader() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8')
    .append('application','x-www-form-urlencoded')
    .append('Access-Control-Allow-Credentials','true')
    .append('Access-Control-Allow-Methods','*')
    .append('Access-Control-Allow-Origin','*')
    .append('tenant',localStorage.getItem('tenentDBName'))


    const httpOptions = {
        headers: headers,
        withCredentials: true
       };
    return httpOptions
}

  getUrl(suffix) {
    return this.url.get('api') + (suffix ? suffix : this.url.get('suffix'));
  }

  getItems(suffix = null) {
    // return this.http.get(this.getUrl(suffix), this.getHeader());
  }

  getXItems(obj, suffix = null) {
    // return this.http.post(this.getUrl(suffix), obj, this.getHeader())
    //                 .map().then(response => response.json())
    //                 .catch(data => this.handleError(data));
  }

  getItem(id, suffix = null) {
    return this.http.get(this.getUrl(suffix) + '/' + id, this.getHeader())
  }

  addItem(obj, suffix = null) {
    // return this.http.post(this.getUrl(suffix), obj, this.getHeader())
    //                 .toPromise().then(response => response.json())
    //                 .catch(data => this.handleError(data));
  }

  updateItem(id, obj, suffix = null) {
    // return this.http.put(this.getUrl(suffix) + '/' + id, obj, this.getHeader()).toPromise().then(response => response.json())
    // .catch(data => this.handleError(data));
  }

  removeItem(id, suffix = null) {
    // return this.http.delete(this.getUrl(suffix) + '/' + id, this.getHeader())
    //                 .toPromise().then(response => response.json())
    //                 .catch(data => this.handleError(data));
  }

  deleteItem(obj, suffix = null) {
    // const headers = new Headers({ user_id:localStorage.getItem('userID'), userID:localStorage.getItem('userID'), 'Authorization': `Bearer ${localStorage.getItem('token')}`});
    // return this.http.delete(this.getUrl(suffix), this.getHeader())
    //                 .toPromise().then(response => response.json())
    //                 .catch(data => this.handleError(data));
  }

  toggleStatus(obj, suffix = null) {
    // return this.http.put(this.getUrl(suffix), obj, this.getHeader())
    //                 .toPromise().then(response => response.json())
    //                 .catch(data => this.handleError(data));
  }

  getCountries(language) {
    // return this.getItems(language, 'countries');
  }

  handleError(error,status=null) {    
    // if(status != null){
    //   if(status===400){
    //     this.notifier.notify('error', JSON.parse(error.response).msg);
    //   }
    //   if(error.hasOwnProperty('status')) {
    //     if(error.status===403){
    //       this.notifier.notify('error', 'Unauthorized Access.');
    //     }else if(error.headers._headers.get('content-type')[0] == "application/json; charset=utf-8") {          
    //       this.notifier.notify('error', error.json().msg);
    //     } else {
    //       this.notifier.notify('error', 'Unable process your request.');
    //     }
    //   }
    //   return null;
    // }    
  }
}