export const environment = {
  production: false,
  config: {
    API_URL: "http://104.211.22.42:8085",  
    mapbox: {
      access_token : "pk.eyJ1Ijoiem9uYWwyMDEzIiwiYSI6InU2eF9kNlkifQ.TjcO4x2jF6dpM7rtsYWXlQ"
    }
  }
};

